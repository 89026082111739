.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
  width: 100%;
  height: 280px;
}

.portfolio__item h3 {
  margin: 1.2rem 0 1.2rem;
  text-align: center;
}

.portfolio__item .description {
  font-size: 0.9rem;
  color: var(--color-light);
  margin: 1.2rem 0 1.2rem;
  text-align: center;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  justify-content: center;
}

/* --- MEDIA QUERIES (MEDIUM DEVICES E.G. TABLETS) --- */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2rem;
  }
  .portfolio__item-image {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 140px;
  }
}

/* --- MEDIA QUERIES (SMALL DEVICES E.G. SMARTPHONES) --- */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
}
